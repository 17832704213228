<template>
  <v-app class="bg-grey" style="max-width: 100vw">
    <v-navigation-drawer color="primary" v-model="visible" app>
      <v-list nav class="pr-0">
        <v-list-item class="hidden-sm-and-up">
          <v-list-item-content>
            <v-img class="ml-0" max-height="140" contain src="@/assets/img/logoOptia.png" position="left"></v-img>
          </v-list-item-content>
        </v-list-item>
        <v-list-item class="pr-0 mb-0 white--text" :to="item.link" v-for="item in users_routes" :key="item.title" link>
          <v-list-item-icon>
            <v-icon color="white">{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="white--text">{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item class="pr-0 mb-0 white--text" v-if="logged_user !== null &&(isResponsableOrAdmin || hasPermission('view_asset'))" @click="scannerDialog = true">
          <v-list-item-icon>
            <v-icon color="white">mdi-barcode</v-icon>
          </v-list-item-icon>
          <v-list-item-content class="white--text">Scanner le code-barre</v-list-item-content>
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <v-list-item class="pr-0 mb-0 white--text" disabled v-if="logged_user !== null">
          <v-list-item-icon>
            <v-icon color="white">mdi-check-circle</v-icon>
          </v-list-item-icon>
          <v-list-item-content class="white--text font-italic">Optia Web ver 0.9.59</v-list-item-content>
        </v-list-item>
      </template>
    </v-navigation-drawer>
    <v-navigation-drawer class="overflow-y-auto" v-model="drawerDialog" width="600" absolute temporary right v-if="$vuetify.breakpoint.xsOnly !== true">
      <component :is="drawerComponent" v-bind="drawerProperties"></component>
    </v-navigation-drawer>
    <v-dialog v-else v-model="drawerDialog">
      <component :is="drawerComponent" v-bind="drawerProperties"></component>
    </v-dialog>
    <v-dialog persistent v-model="scannerDialog" max-width="640">
      <BarcodeScanner :dialog-visibility="scannerDialog" @close-scanner-dialog="scannerDialog = false;"/>
    </v-dialog>
    <v-app-bar app height="100" style="max-width: 100vw" dark color="primary" class="pa-0">
      <v-app-bar-nav-icon @click="visible = !visible"></v-app-bar-nav-icon>
      <v-img class="ml-0 hidden-xs-only" max-height="124" contain src="@/assets/img/logoOptia.png"
             position="left"></v-img>
      <v-img class="ml-0 hidden-xs-only" max-height="100" v-if="societyLogo !== undefined" contain
             :src="societyLogo.file"
             position="left"></v-img>

      <v-card color="primary" flat v-if="loggedUser !== null">
        <v-row align="center">
          <v-col cols="2">
            <router-link to="/commentaires">
              <v-icon>mdi-message</v-icon>
            </router-link>
          </v-col>
          <v-col cols="2">
            <router-link to="/help">
              <v-icon>mdi-help-circle</v-icon>
            </router-link>
          </v-col>
          <v-col cols="2">
            <router-link to="/profil">
              <v-icon>mdi-account-outline</v-icon>
            </router-link>
          </v-col>
          <v-col cols="6">
            <span class="font-weight-bold">{{ loggedUser.fullname }}</span><br/>
            <a class="text-decoration-none white--text" @click="handleLogout">Se déconnecter</a>
          </v-col>
        </v-row>
      </v-card>
    </v-app-bar>
    <v-dialog
        v-model="hasAlert"
        width="500"
    >

      <v-card>
        <v-card-title :color="alert.type" class="headline grey lighten-2">
          {{ alert.title }}
        </v-card-title>

        <v-card-text>
          <pre class="ma-2 mt-3 body-1">{{ alert.message }}</pre>
          <div class="mt-2" v-if="alert.details !== null && alert.details !== undefined">
            <h4>Plus d'informations</h4>
            <ErrorList :value="alert.details"/>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-main class="bg-grey">
      <router-view></router-view>
      <v-snackbar v-model="announce.hasMessage" :timeout="3000" :color="announce.type">
        {{ announce.message }}
      </v-snackbar>

    </v-main>

  </v-app>
</template>

<script>


import {ALLOWED_ROUTES} from "@/constants/rules";
import BarcodeScanner from "@/BarcodeScanner";
import {AuthMixin} from "@/mixins/util/AuthMixin";
import ErrorList from "@/components/base/ErrorList";
import {DrawerMixin} from "@/mixins/util/DrawerMixin";
import {getAllowedRoutes} from "@/router/functions";
// import Quagga from 'quagga';

export default {
  name: 'App',

  components: {ErrorList, BarcodeScanner},
  mixins: [AuthMixin, DrawerMixin],
  async mounted() {
    if (this.$store.getters["config/allConfigs"].length === 0 && this.loggedUser !== null) {
      await this.$store.dispatch("config/fetchConfigs");
    }
  },
  data: () => ({
    items: [
      {title: 'Tableau de bord', icon: 'mdi-view-dashboard', "link": "/"},
      {title: 'Tickets', icon: 'mdi-ticket', "link": "/tickets"},
      {title: 'Interventions', icon: 'mdi-hammer-wrench', "link": "/interventions"},
      {title: 'Équipements', icon: 'mdi-wrench', "link": "/assets"},
      {title: "Plans", icon: 'mdi-map', "link": "/plans"},
      {title: 'Contrats', icon: 'mdi-file-document-multiple-outline', "link": "/contracts"},
      {title: 'Intervenants', icon: 'mdi-account', "link": "/intervenants"},
      {title: 'Finances', icon: 'mdi-currency-eur', "link": "/finance"},
      {title: 'Statistiques', icon: 'mdi-chart-bar', "link": "/stats"},
      {title: 'Administration', icon: 'mdi-cog', "link": "/admin"},
    ],
    visible: null,
    errorDetected: false,
    errorMessage: "",
    scannerDialog: false,
    ALLOWED_ROUTES: ALLOWED_ROUTES,
  }),
  computed: {
    snackbar: {
      get: function () {
        return this.$store.getters["messages/hasMessage"];
      },
      set: function (value) {
        return this.$store.commit("messages/changeVisibility", value);
      }
    },
    societyLogo() {
      return this.$store.getters["config/societyLogo"];
    },
    hasAlert: {
      get() {
        return this.$store.getters["alerts/hasMessage"];
      },
      set(value) {
        return this.$store.commit("alerts/changeVisibility", value)
      }
    },
    alert() {
      return this.$store.getters["alerts/announce"];
    },
    announce: {
      get: function () {
        return this.$store.getters["messages/announce"];
      }
    },
    loggedUser() {
      return this.$store.getters["users/getLoggedUser"];
    },
    users_routes() {
      if (this.loggedUser === null) {
        return [];
      } else if (this.loggedUser.group in ALLOWED_ROUTES) {
        return this.items.filter(value => getAllowedRoutes(this.logged_user).indexOf(value.title) !== -1);
      } else if (this.loggedUser.group === "Responsable technique") {
        return this.items;
      } else {
        return this.items;
      }
    },

  },
  methods: {
    async handleLogout() {
      await this.$store.dispatch("users/logout");
      await this.$router.push("/login");
    }
  },
  watch: {
    loggedUser: async function () {
      if (this.$store.getters["config/allConfigs"].length === 0 && this.loggedUser !== null) {
        await this.$store.dispatch("config/fetchConfigs");
      }
    },
  },
  errorCaptured(err) {
    this.$store.commit("alerts/end");
    console.error(err);
    let details = err.response?.data !== undefined ? err.response.data : null;
    this.$store.dispatch("alerts/announceError", {
      details: details,
      message: "La dernière opération n'a pas pu être effectuée."
    })
  },

};
</script>
<style lang="scss">
$body-font-family: 'Trebuchet';
$title-font: 'Helvetica';
.bg-grey {
  background: #ECEFF1;
}

.v-application {
  font-family: $body-font-family, sans-serif !important;

  .title { // To pin point specific classes of some components
    font-family: $title-font, sans-serif !important;
  }
}
</style>