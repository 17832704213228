<template>
  <div>
    <v-sheet height="600" class="mt-4">
      <v-select label="Intervenants" v-model="intervenant" :items="intervenants" item-text="name" item-value="name" class="mx-2">
      </v-select>
      <vue-cal
          class="vuecal--green-theme"
          locale="fr"
          :time-from="8 * 60"
          :time-to="19 * 60"
          :time-step="30"
          today-button
          :events="filteredEvents"
          :active-view="activeView"
          :on-event-click="onEventClick"
          :disable-views="['years']"/>
    </v-sheet>
  </div>
</template>

<script>
import DateUtilMixin from "@/mixins/util/DateUtilMixin";
import VueCal from 'vue-cal'
import 'vue-cal/dist/i18n/fr.es.js'
import 'vue-cal/dist/vuecal.css'

export default {
  name: "Agenda",
  mixins: [DateUtilMixin],
  components: {"vue-cal": VueCal},
  mounted() {
    if(this.$store.getters["interventions/interventions"].length === 0) {
      this.$store.dispatch("interventions/fetchInterventions");
    }
    if(this.$store.getters["tickets/allTickets"].length === 0) {
      this.$store.dispatch("tickets/fetchTickets");
    }
  },
  data() {
    return {
      activeView: this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs ? "day" : "week",
      intervenant: null,
    }
  },
  computed: {
    events() {
      return this.$store.getters["interventions/events"];
    },
    filteredEvents() {
      let that = this;
      return this.events.filter(function (event) {
        if(that.intervenant === null) {
          return true;
        } else if(that.intervenant !== event.intervenant) {
          return false;
        }
        return true;
      });
    },
    intervenants() {
      let intervenantSet = new Set(this.$store.getters["interventions/events"].map((item) => item.intervenant));
      let intervenantList = Array.from(intervenantSet);
      return intervenantList.map((item) => ({"name": item}));
    }
  },
  methods: {
    onEventClick(event, e) {
      let path = `/interventions/${event.id}`;
      this.$router.push(path);
      e.stopPropagation();
    }
  },
}
</script>

<style scoped>

</style>