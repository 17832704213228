import config from "@/config";
import axios from "axios";

/**
 * toast notification module
 */
export const emplacementStore = {
    namespaced: true,
    state() {
        return {
            emplacements: [],

        }
    },
    mutations: {
        setEmplacements(state, emplacements) {
            state.emplacements = emplacements;
        },
        addEmplacement(state, emplacement) {
            state.emplacements = [...state.emplacements, emplacement];
        },
        updateEmplacement(state, emplacement) {
            state.emplacements = state.emplacements.map((e) => e.id === emplacement.id ? emplacement : e);
        },
        removeEmplacement(state, emplacement) {
            state.emplacements = state.emplacements.filter((e) => e.id !== emplacement.id);
        }
    },
    getters: {
        emplacements(state) {
            return state.emplacements;
        }
    },
    actions: {
        async fetchEmplacementsByPlanId({commit, dispatch, rootGetters}, planId) {
            let axiosConfig = rootGetters["users/authentificationConfig"];
            let url = `${config.BASE_URL}/api/emplacements/?plan=${planId}`;
            try {
                let response = await axios.get(url, axiosConfig);
                commit("setEmplacements", response.data);
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root: true});
            }
        },
        async postEmplacement({commit, dispatch, rootGetters}, emplacement) {
            let axiosConfig = rootGetters["users/authentificationConfig"];
            let url = `${config.BASE_URL}/api/emplacements/`;
            try {
                let response = await axios.post(url, emplacement, axiosConfig);
                commit("addEmplacement", response.data);
                return response.data;
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root: true});
                return null;
            }
        },
        async patchEmplacement({commit, dispatch, rootGetters}, emplacement) {
            let axiosConfig = rootGetters["users/authentificationConfig"];
            let url = `${config.BASE_URL}/api/emplacements/${emplacement.id}/`;
            try {
                let response = await axios.patch(url, emplacement, axiosConfig);
                commit("updateEmplacement", response.data);
                return response.data;
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root: true});
                return null;
            }
        },
        async deleteEmplacement({commit, dispatch, rootGetters}, emplacement) {
            let axiosConfig = rootGetters["users/authentificationConfig"];
            let url = `${config.BASE_URL}/api/emplacements/${emplacement.id}/`;
            try {
                await axios.delete(url, axiosConfig);
                commit("removeEmplacement", emplacement);
                return "success";
            } catch (e) {
                dispatch("alerts/handleAxiosError", e, {root: true});
                return "failure";
            }
        },
    }
}
